var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.$t(`SIDEBAR.${_vm.name}`)),expression:"$t(`SIDEBAR.${name}`)",modifiers:{"right":true}}],staticClass:"w-10 h-10 my-2 flex items-center justify-center rounded-lg relative",class:{
      'bg-black-800 text-black-50 hover:bg-black-700':
        isActive || _vm.isChildMenuActive,
      'text-black-600 hover:text-black-50 hover:bg-black-700':
        !(isActive || _vm.isChildMenuActive),
    },attrs:{"href":href,"rel":_vm.openInNewPage ? 'noopener noreferrer nofollow' : undefined,"target":_vm.openInNewPage ? '_blank' : undefined},on:{"click":navigate}},[_c('fluent-icon',{class:{
        'text-black-50': isActive || _vm.isChildMenuActive,
      },attrs:{"icon":_vm.icon}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.name))]),_vm._v(" "),(_vm.count)?_c('span',{staticClass:"text-black-900 bg-yellow-500 absolute -top-1 -right-1"},[_vm._v("\n      "+_vm._s(_vm.count)+"\n    ")]):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }